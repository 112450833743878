export const translateModules = t => ({
  MENU_PLANNER: t('common.mainMenu.menuPlanner', 'Planowanie menu'),
  MENU_PLANNER_AUTO_FILL: t(
    'rolesForm.autoFill',
    'Planowanie menu - automatyczne uzupełnianie'
  ),
  MENU: t('common.mainMenu.menuPlanner', 'Planowanie menu'),
  SIZE: t('common.mainMenu.sizes', 'Wielkości'),
  BAG_CONTAINER: t('common.mainMenu.bagContainer', 'Torby (opakowania)'),
  CONTRACTOR: t('rolesForm.contractors', 'Kontrahenci'),
  MEAL_TYPE: t('common.mainMenu.typesOfMeals', 'Typy posiłków'),
  DIET: t('$*common.diets', 'Diety'),
  INGREDIENT: t('common.mainMenu.ingredients', 'Składniki'),
  RECIPE: t('common.mainMenu.recipes', 'Przepisy'),
  DISH: t('common.mainMenu.meals', 'Dania'),
  PACKAGE: t('common.mainMenu.packages', 'Pakiety'),
  PACKAGE_LINE_FILTER: t(
    'common.mainMenu.packagesLineFilter',
    'Filtry linii diet'
  ),
  VARIANT: t('common.mainMenu.variants', 'Warianty'),
  DICTIONARY: t('common.mainMenu.dictionariesCaption', 'Słowniki'),
  COMPANY: t('rolesForm.company', 'Ustawienia'),
  USER: t('common.mainMenu.clients', 'Klienci'),
  EMPLOYEE: t('common.mainMenu.myCompanyEmployees', 'Pracownicy'),
  CLIENT_CALL: t('rolesForm.clientCall', 'Połączenia z klientami'),
  IMPERSONATE_USER: t('rolesForm.impersonating', 'Podszywanie sie pod klienta'),
  BRAND: t('common.mainMenu.myCompanyBrands', 'Marki'),
  SHOW_MENU_PAGE: t('rolesForm.menuPage', 'Strona z menu'),
  ROLE: t('common.mainMenu.myCompanyRoles', 'Role'),
  ZONE_CATEGORY: t('common.mainMenu.zoneCategories', 'Kategorie stref'),
  ZONE: t('common.mainMenu.zones', 'Strefy'),
  BAG: t('ecommerceBags.name', 'Lista toreb'),
  BASKET: t('basket.menu', 'Koszyki'),
  COST: t('common.mainMenu.priceLists', 'Cenniki'),
  ADDRESS: t('clients.addresses', 'Adresy'),
  DISCOUNT_CODE: t('common.mainMenu.discountCodes', 'Kody rabatowe'),
  DRIVER: t('common.mainMenu.drivers', 'Kierowcy'),
  MONEY_BOX_HISTORY: t('clients.moneybox', 'Skarbonka'),
  ORDER: t('clients.orders', 'Zamówienia'),
  NOTE: t('rolesForm.clientNotes', 'Globalne zarządzanie notatkami'),
  SMS: t('rolesForm.clientSMS', 'SMS do klienta'),
  EMAIL: t('rolesForm.clientEmail', 'E-mail do klienta'),
  RATE: t('rolesForm.rates', 'Oceny'),
  SUB_BRAND: t('common.mainMenu.subBrands', 'Paramarki'),
  SUB: t('common.mainMenu.subBrands', 'Paramarki'),
  OVERPRODUCTION: t('list.additionalOrders', 'Zam. dodatkowe'),
  HOVER: t('common.mainMenu.hover', 'Hover'),
  WASTE: t('common.mainMenu.waste', 'Straty'),
  MONEY_BOX_CONFIGURATION: t(
    'common.mainMenu.moneyBoxConfig',
    'Konfiguracja skarbonki'
  ),
  MAIL_CONFIGURATION: t(
    'common.mainMenu.myCompanyMailNotifications',
    'Powiadomienia mailowe'
  ),
  SMS_CONFIGURATION: t(
    'common.mainMenu.myCompanySmsNotifications',
    'Powiadomienia SMS'
  ),
  END_OF_DIET_REMINDER: t(
    'rolesForm.endOfDietReminder',
    'Przypomnienia (ogólne)'
  ),
  END_OF_DIET_REMINDER_MAIL: t(
    'common.mainMenu.myCompanyMailReminders',
    'Przypomnienia mailowe'
  ),
  END_OF_DIET_REMINDER_SMS: t(
    'common.mainMenu.myCompanySmsReminders',
    'Przypomnienia SMS'
  ),
  NOTIFICATION: t('common.mainMenu.messages', 'Komunikaty'),
  REPORT_EMPLOYEE: t('rolesForm.employeeReport', 'Raport - Pracownicy'),
  REPORT_DISH_LIST: t(
    'rolesForm.dishListReport',
    'Raport - Dania do produkcji'
  ),
  REPORT_DISH_STICKERS: t(
    'rolesForm.stickersReport',
    'Raport - Naklejki na dania'
  ),
  REPORT_EMPLOYEE_TIME_ENTRIES: t(
    'common.mainMenu.employeesReport',
    'Raport - Pracowników'
  ),
  REPORT_BAG_STICKERS: t(
    'rolesForm.labelsReport',
    'Raport - Etykiety na torby'
  ),
  REPORT_BAG_STICKERS_MINI: t(
    'rolesForm.labelsReportMini',
    'Raport - Etykiety na torby MINI'
  ),
  REPORT_DISHES_CARD: t('rolesForm.menuCardsReport', 'Raport - Karty dań'),

  REPORT_RECIPES_CARD: t(
    'rolesForm.recipeCardsReport',
    'Raport - Karty przepisów'
  ),
  REPORT_RECIPES_INGREDIENTS: t(
    'rolesForm.recipeIngredientsReport',
    'Raport - Karty przepisów (elementy)'
  ),
  REPORT_SENT_SMS: t('reports.reportSentSms', 'Wysłane SMSy'),
  REPORT_ACCOUNTANT_INCOME: `${t(
    'reports.accountant',
    'Raporty księgowe'
  )} - ${t('reports.accountant.income')}`,
  REPORT_ACCOUNTANT_NEW_ACCOUNTS: `${t(
    'reports.accountant',
    'Raporty księgowe'
  )} - ${t('reports.accountant.newAccounts')}`,
  REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS: `${t(
    'reports.accountant',
    'Raporty księgowe'
  )} - ${t('reports.accountant.depositAndRefunds')}`,
  REPORT_FOR_DRIVER: t('rolesForm.forDriverReport', 'Raport - Dla kierowcy'),
  REPORT_SHOPPING: t('rolesForm.shopListReport', 'Raport - Lista zakupów'),
  REPORT_CONTAINERS: t('rolesForm.containersReport', 'Raport - Pojemniki'),
  REPORT_CONTAINERS_DETAIL: t(
    'rolesForm.containersDetReport',
    'Raport - Pojemniki (szczegółowy)'
  ),
  REPORT_USAGE_DISCOUNT_CODES: t(
    'rolesForm.usedDiscCodesReport',
    'Raport - Użyte kody rabatowe'
  ),
  REPORT_MONEY_BOX_TOP_UP: t(
    'rolesForm.topUpReport',
    'Raport  - Doładowania skarbonki'
  ),
  ADDITIONAL_OFFER: t(
    'common.mainMenu.navLinks.additionalOffer',
    'Oferta dodatkowa'
  ),
  REPORT_OVERPRODUCTION: t(
    'rolesForm.addOrdersReport',
    'Raport - Zam. dodatkowe'
  ),
  REPORT_WASTE: t('rolesForm.lossesReport', 'Raport - Straty'),
  REPORT_CHECKLIST_RECIPES: t(
    'rolesForm.recipesChecklistReport',
    'Raport - Checklista przepisów'
  ),
  REPORT_CHECKLIST_DISHES: t(
    'rolesForm.foodChecklistReport',
    'Raport - Checklista dań'
  ),
  REPORT_ACTIVE_DIET: t('common.mainMenu.reportActiveDiets', 'Raport - Diet'),
  REPORT_ORDERED_DIET: t(
    'common.mainMenu.reportOrderedDiets',
    'Raport zamówionych diet'
  ),
  REPORT_PERSONAL_PICKUPS: t(
    'common.mainMenu.reportPersonalPickups',
    'Raport - Odbiory osobiste'
  ),
  REPORT_PACKERS: t('common.mainMenu.reportPackers', 'Raport - Dla pakowni v2'),
  REPORT_RECIPE_STICKERS: t(
    'common.mainMenu.reportRecipeStickers',
    'Raport - Naklejki na przepisy'
  ),
  REPORT_INCOME_SUMMARY: t('common.mainMenu.reportsIncomeSummary', 'Przychód'),
  REPORT_FOOD_COST: t('rolesForm.raportFoodCost', 'Raport - FoodCost Diet'),
  REPORT_FOOD_COST_BAGS: t(
    'rolesForm.raportFoodCostBags',
    'Raport - FoodCost Toreb'
  ),
  REPORT_INCOMING_SUBSCRIPTION: t(
    'rolesForm.incomingSubscriptionsReport',
    'Nadchodzące odnowienia'
  ),
  REPORT_PURCHASED_ADDONS: t(
    'rolesForm.raportAddonList',
    'Raport - Lista dodatków'
  ),
  REPORT_PURCHASED_ADDONS_BY_CLIENT:
    t('rolesForm.reportPrefix') +
    t('common.mainMenu.reportsAddonsByClientList'),
  REPORT_ARCHIVE: t('reportArchive'),
  PAGE: t('rolesForm.subpages', 'Podstrony'),
  MASS_SMS: t('rolesForm.massSMS', 'Masowa wysyłka smsów'),
  MASS_MAIL: t('rolesForm.massMail', 'Masowa wysyłka emaili'),
  MASS_ACTION: t('massAction.dietElements.title', 'Masowa edycja oferty firmy'),
  IMPORTER_TASK: t('rolesForm.importer', 'Importer'),
  STATISTICS_DIET: t('rolesForm.statistics', 'Statystyki'),
  STATISTICS_DIET_INCOMES: t(
    'rolesForm.statisticsIncomes',
    'Statystyki - przychody'
  ),
  STATISTICS_DIET_SEX: t(
    'rolesForm.statisticsGender',
    'Statystyki - płeć klientów'
  ),
  STATISTICS_DIET_KNOW_ABOUT: t(
    'rolesForm.statisticsAboutUs',
    'Statystyki - skąd o nas wiesz'
  ),
  STATISTICS_ECOMMERCE_DAY_ORDERS: t(
    'rolesForm.statisticEcommerceDayOrders',
    'Statystyki - Ilość zamówień dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_DAY_BAGS: t(
    'rolesForm.statisticEcommerceDayBags',
    'Statystyki - Ilość toreb dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_DAY_INCOME: t(
    'rolesForm.statisticEcommerceDayIncome',
    'Statystyki - Przychód dla danego dnia'
  ),
  STATISTICS_CORE_DAY_USERS: t(
    'rolesForm.statisticCoreDayUsers',
    'Statystyki - Ilość utworzonych kont dla daniego dnia'
  ),
  STATISTICS_CORE_DAY_MONEY_BOXES: t(
    'rolesForm.statisticCoreDayMoneyBoxes',
    'Statystyki - Ilość dodanych dla użytkowników punktów dla danego dnia'
  ),
  STATISTICS_ECOMMERCE_YEAR_BAGS: t(
    'rolesForm.statisticEcommerceYearBags',
    'Statystyki - Ilość toreb dla danego roku'
  ),
  STATISTICS_ECOMMERCE_YEAR_INCOME: t(
    'rolesForm.statisticEcommerceYearIncome',
    'Statystyki - Przychód dla danego roku'
  ),
  MAILING: t('rolesForm.mailing', 'Przypomnienia mailowe (aktywacja)'),
  SMSING: t('rolesForm.smsing', 'Przypomnienia SMS (aktywacja)'),
  MODULE_ADDONS: t(
    'rolesForm.addonsModuleSettings',
    'Ustawienia modułu - Dodatki'
  ),
  MODULE_SHOP: t('common.mainMenu.shop'),
  ADMIN_NAV_LINK: t('rolesForm.menuShortcuts', 'Skróty menu'),
  REPORT_ADVANCED_DISH_PDF: t(
    'rolesForm.advancedPdfRaport',
    'Zaawansowany raport dania PDF'
  ),
  MODULE_DISH_PDF_GENERATOR: t(
    'rolesForm.advancedDishConfigurationModule',
    'Moduł konfiguracji zaawansowanego dania'
  ),
  MODULE_MENU_SELECTION_ASSISTANT: t(
    'rolesForm.menuSelectionAssistantModule',
    'Moduł Kasia'
  ),
  SYSTEM_ADVANCED_DISH: t(
    'rolesForm.advancedDishView',
    'Widok dania zaawansowany'
  ),
  ADDON: t('rolesForm.addon', 'Dodatki'),
  BRAND_ACCESS_KEY: t('brandCfg.accessKeysLabel', 'Klucze dostępu'),
  FEED: t('brandCfg.productFeedLabel', 'Product Feed'),
  ROUTE_POINT: t('routePlanner.menuTitle', 'Planer tras'),
  ROUTE_MANAGER: t('routeManager.menuTitle', 'Menager tras'),
  MODULE_SETTLEMENTS: t('moduleSettlements'),
  ADDRESS_REQUESTS: t('rolesForm.addressRequests', 'Zapytania o kod pocztowy'),
  REPORT_CHECKLIST_DIETS: t(
    'rolesForm.dietsChecklistReport',
    'Raport - Checklista diet'
  ),
  MENU_SUMMARY: t('rolesForm.menuSummary', 'Podsumowanie menu'),
  REPORT_CHECKLIST_ALTERED_DIETS: t(
    'rolesForm.alteredDietsChecklistReport',
    'Raport - Checklista wyborów'
  ),
  TRANSLATION: t('common.mainMenu.translations', 'Tłumaczenia'),
  REPORT_MACRO_INGREDIENTS: t(
    'common.mainMenu.macroIngredientsReport',
    'Raport - Wartości odżywcze'
  ),
  USE_MINI_ADDRESS_FORM: t(
    'common.rolesForm.useLessFieldsForm',
    'Użyj skróconego formularza adresu'
  ),
  REPORT_TOTAL_BEL: t('rolesForm.totalBelReport', 'Raport - Total Bel'),
  BRAND_POWER_BI: t('common.rolesForm.modulePowerBI', 'Moduł PowerBI'),
  TASKS_GENERATOR: t('common.mainMenu.tasksGenerator', 'Generuj flow'),
  KITCHEN: t('common.mainMenu.kitchen', 'Kuchnia'),
  DISH_PACKHOUSE: t('common.mainMenu.dishesPackhouse', 'Pakownia dań'),
  BAGS_PACKHOUSE: t('common.mainMenu.bagsPackhouse', 'Pakownia toreb'),
  CMS_DIET: t('common.mainMenu.CMS.diets', 'Diety'),
  CMS_DISH: t('common.mainMenu.CMS.dishes', 'Dania'),
  CMS_ADDON: t('common.mainMenu.CMS.addons', 'Dodatki'),
  CMS_MENU: t('common.mainMenu.CMS.menu', 'Menu'),
  HOME_PAGE: t('common.mainMenu.CMS.homePage', 'Strona Główna'),
  CMS_ZONE: t('common.mainMenu.CMS.zone', 'Strefy dostaw'),
  BLOG_POST: t('common.mainMenu.CMS.blog', 'Blog'),
  PRODUCT: t('common.mainMenu.products', 'Produkty'),
  SEO: t('common.rolesForm.seo', 'SEO'),
  CMS_SUBPAGE: t('common.mainMenu.CMS.subPage', 'Podstrony'),
  MODULE_GOOGLE_ANALYTICS: t(
    'common.rolesForm.integrations.googleAnalytics',
    'GoogleAnalytics'
  ),
  MODULE_GOOGLE_TAG_MANAGER: t(
    'common.rolesForm.integrations.googleTagMenager',
    'GoogleTagManager'
  ),
  MODULE_HOT_JAR: t('common.rolesForm.integrations.hotjar', 'HotJar'),
  MODULE_FACEBOOK_PIXEL: t(
    'common.rolesForm.integrations.facebookPixel',
    'FacebookPixel'
  ),
  MODULE_MY_LEAD: t('common.rolesForm.integrations.mylead', 'MyLead'),
  MODULE_SALES_MANAGO: t(
    'common.rolesForm.integrations.salesManago',
    'SalesManago'
  ),
  MODULE_GET_RESPONSE: t(
    'common.rolesForm.integrations.getResponse',
    'GetResponse'
  ),
  MODULE_IPRESSO: t('common.rolesForm.integrations.ipresso', 'Ipresso'),
  MODULE_USER_COM: t('common.rolesForm.integrations.usercom', 'UserCom'),
  MODULE_CLICK_ON_METRICS: t(
    'common.rolesForm.integrations.clickOnMetrics',
    'ClickOnMetrics'
  ),
  MODULE_POLLSTER_TRACK: t(
    'common.rolesForm.integrations.pollsterTrack',
    'PollsterTrack'
  ),
  MODULE_BITRIX_24: t('common.rolesForm.integrations.bitrix', 'Bitrix24'),
  MODULE_TRUSTED_SHOPS: t(
    'common.rolesForm.integrations.trustedShops',
    'TrustedShops'
  ),
  MODULE_THULIUM: t('common.rolesForm.integrations.thulium', 'Thulium'),
  MODULE_OMNICONVERT: t(
    'common.rolesForm.integrations.omniconvert',
    'Omniconvert'
  ),
  STATISTICS_ECOMMERCE_CALCULATION_DATA: t(
    'common.rolesForm.settlements.statistics'
  ),
  DISH_PHOTO_GALLERY: t('common.rolesForm.dishesPhotoGallery', 'Zdjęcia dań'),
  INGREDIENT_PRICE: t(
    'common.rolesForm.ingredient.price',
    'Aktualizator cen składników'
  ),
  INGREDIENT_TRANSLATIONS: t(
    'common.rolesForm.ingredient.translations',
    'Aktualizacja translacji składników'
  ),
  DISH_TRANSLATIONS: t(
    'common.rolesForm.dishes.translations',
    'Aktualizacja translacji dań'
  ),
  CLIENT_METRIC: t('common.rolesForm.clientMetric', 'Kalkulator BMI'),
  ROLE_SHOW_REPORT_DISPOSAL: t('common.rolesForm.disposal', 'Utylizacja'),
  ROLE_SHOW_REPORT_SELECTIONS: t(
    'common.roles.role.showReportSelections',
    'Kody v3 wybory'
  ),
  COPY_BRAND_CONFIGURATION: t(
    'common.rolesForm.copyBrandConfiguration',
    'Kopiowanie ustawień marki'
  ),
});
