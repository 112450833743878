import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import DEFAULT_SALES_MARKET_LANG from 'helpers/defaultSalesMarketLang';
import useGetSalesMarketLang from 'hooks/redux/Brands/useGetSalesMarketLang';

import MIME_TYPES_DATA from '../constants/mimeTypesData';
import DownloadReportButton from './DownloadReportButton/DownloadReportButton';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyleType: 'none',
    margin: '0',
    padding: '0',
  },
});

const DownloadReportButtons = ({
  mimeTypes,
  handleGenerate,
  areButtonsDisabled,
  isSalesMarketLangViewEnabled,
  mimeTypesData,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const salesMarketLang = useGetSalesMarketLang();

  const isDefaultSalesMarketLang =
    salesMarketLang === DEFAULT_SALES_MARKET_LANG;
  const isSalesMarketLangViewShown =
    isSalesMarketLangViewEnabled && !isDefaultSalesMarketLang;

  return (
    <div>
      <ul className={classes.list}>
        {mimeTypes.map(mimeType => {
          const { extension, translationKey, translationValues } =
            mimeTypesData[mimeType];

          return (
            <li key={mimeType}>
              <DownloadReportButton
                extension={extension}
                isDisabled={areButtonsDisabled}
                onClick={() => {
                  handleGenerate(mimeType, DEFAULT_SALES_MARKET_LANG);
                }}
              >
                {t(translationKey, translationValues)}{' '}
                {isSalesMarketLangViewShown && (
                  <>- {DEFAULT_SALES_MARKET_LANG}</>
                )}
              </DownloadReportButton>
            </li>
          );
        })}
      </ul>
      {isSalesMarketLangViewShown && (
        <ul className={classes.list}>
          {mimeTypes.map(mimeType => {
            const { extension, translationKey, translationValues } =
              mimeTypesData[mimeType];

            return (
              <li key={mimeType}>
                <DownloadReportButton
                  extension={extension}
                  isDisabled={areButtonsDisabled}
                  onClick={() => {
                    handleGenerate(mimeType, salesMarketLang);
                  }}
                >
                  {t(translationKey, translationValues)} - {salesMarketLang}
                </DownloadReportButton>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

DownloadReportButtons.propTypes = {
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleGenerate: PropTypes.func.isRequired,
  areButtonsDisabled: PropTypes.bool.isRequired,
  isSalesMarketLangViewEnabled: PropTypes.bool,
  mimeTypesData: PropTypes.objectOf(
    PropTypes.shape({
      extension: PropTypes.string.isRequired,
      translationKey: PropTypes.string.isRequired,
      translationValues: PropTypes.objectOf(PropTypes.any),
    })
  ),
};

DownloadReportButtons.defaultProps = {
  isSalesMarketLangViewEnabled: false,
  mimeTypesData: MIME_TYPES_DATA,
};

export default DownloadReportButtons;
