import { useEffect, useState } from 'react';
import CardBody from '../../../components/Card/CardBody';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
} from '../../../actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesDefaultState, modulesNames } from '../consts';
import { DialogLoader } from '../../../components/DialogLoader';
import { columnConfigFunction, setAnalystComponent } from './columnConfig';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button';
import { isGranted } from 'helpers/helpers';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import CardWrapper from 'components/Card/CardWrapper';
import {
  dangerColor,
  goldColor,
  successColor,
} from 'assets/jss/material-dashboard-pro-react';
import makeStyles from '@material-ui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles({
  description: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    minHeight: '54px',
    paddingTop: '20px',
  },
  activeStatus: {
    color: successColor[0],
  },
  disabledStatus: {
    color: dangerColor[0],
  },
  settings: {
    backgroundColor: goldColor,
  },
  dialogContent: {
    width: '100%',
    '@media (min-width: 600px)': {
      width: '500px',
    },
  },
  dialogTitle: {
    borderBottom: '1px solid rgba(203, 161, 50, 1)',
    paddingBottom: '10px',
    width: '90%',
    margin: '0 auto 20px auto',
  },
  dialogActions: {
    justifyContent: 'center',
    margiTop: '20px',
    marginBottom: '20px',
  },
});

const AnalyticsTools = ({
  t,
  classes: propClasses,
  openToast,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
}) => {
  const classes = useStyles();

  const columnConfig = columnConfigFunction(t);
  const [modules, setModules] = useState(modulesDefaultState);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogIndex, setDialogIndex] = useState(false);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(
        selectedBrand,
        Object.values(modulesNames)
      );

      setModules(modulesConfig?.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules?.[type], ...data };
    setModules(prevState => ({
      ...prevState,
      [type]: module,
    }));
  };

  const handleSubmit = async module => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, {
        [module]: modules[module],
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [
          t('errors.modulesCouldNotBeSaved', 'Nie udało się zapisać modułów'),
          error.message,
        ],
        type: 'error',
        autoHideDuration: 300,
      });
    }
  };
  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      {!isLoading && modules && (
        <>
          <GridContainer>
            {columnConfig.map((tool, index) => {
              return (
                isGranted(tool.roleShow) && (
                  <GridItem xs={12} sm={6} md={6} lg={4}>
                    <CardWrapper title={tool?.moduleShowTitle}>
                      <CardBody>
                        <p className={classes.description}>
                          {t(
                            '$*analytics.tools.integration.status.description',
                            'Status integracji:'
                          )}
                          {modules?.[tool?.moduleName]?.enabled ? (
                            <span className={classes.activeStatus}>
                              <b>
                                {' '}
                                {t(
                                  '$*analytics.tools.integration.status.true',
                                  'Aktywna'
                                )}
                              </b>
                            </span>
                          ) : (
                            <span className={classes.disabledStatus}>
                              <b>
                                {' '}
                                {t(
                                  '$*analytics.tools.integration.status.false',
                                  'Nie aktywna'
                                )}
                              </b>
                            </span>
                          )}
                        </p>
                      </CardBody>
                      <CardFooter>
                        <GridContainer justify="flex-end">
                          <Button
                            className={classes.settings}
                            onClick={() => {
                              setDialogIndex(index);
                            }}
                          >
                            {t('$*analytics.tools.settings', 'Ustawienia')}
                          </Button>
                        </GridContainer>
                      </CardFooter>
                    </CardWrapper>
                  </GridItem>
                )
              );
            })}
          </GridContainer>

          <Dialog
            classes={{
              root: classNames(propClasses.center, propClasses.modalRoot),
              paper: propClasses.modal,
            }}
            open={dialogIndex ? true : dialogIndex === 0}
            onClose={() => setDialogIndex(false)}
            aria-labelledby="classic-modal-slide-title"
            aria-describedby="classic-modal-slide-description"
          >
            <div className={classes.dialogContent}>
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={propClasses.modalHeader}
              >
                <h2
                  className={classNames(
                    classes.dialogTitle,
                    propClasses.modalTitle
                  )}
                >
                  {columnConfig[dialogIndex]?.moduleShowTitle}
                </h2>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={propClasses.modalBody}
              >
                <div>
                  {setAnalystComponent(
                    columnConfig?.[dialogIndex]?.component,
                    propClasses,
                    handleModuleUpdate,
                    modules[columnConfig?.[dialogIndex]?.moduleName]
                  )}
                </div>
              </DialogContent>
              <DialogActions
                className={classNames(
                  classes.dialogActions,
                  propClasses.modalFooter
                )}
              >
                <Button
                  onClick={() => {
                    handleSubmit(columnConfig[dialogIndex]?.moduleName);
                    setDialogIndex(false);
                  }}
                  color="success"
                  disabled={!isGranted(columnConfig?.[dialogIndex]?.roleEdit)}
                >
                  {t('$*analytics.tools.button.save', 'Zapisz')}
                </Button>
                <Button onClick={() => setDialogIndex(false)} color="danger">
                  {t('$*analytics.tools.button.close', 'Zamknij')}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand } }) => ({
      selectedBrand,
    }),
    { fetchBrandConfigModulesPack, updateBrandConfigModulesPack }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(AnalyticsTools);
