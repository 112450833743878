import { useSelector } from 'react-redux';

import DEFAULT_SALES_MARKET_LANG from 'helpers/defaultSalesMarketLang';

const useGetSalesMarketLang = () => {
  return useSelector(({ Brands }) => {
    return Brands.brand.multinational.salesMarket || DEFAULT_SALES_MARKET_LANG;
  });
};

export default useGetSalesMarketLang;
