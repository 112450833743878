import React from 'react';

import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { combineStyles } from 'helpers/helpers';
import { compose } from 'redux';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { commonInitialBenefitState } from './HomePage';
import { FormTextInput } from 'components';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import Benefit from './Benefit';
import Add from '@material-ui/icons/Add';
import CardWrapper from 'components/Card/CardWrapper';

const Description = ({
  t,
  classes,
  state,
  setState,
  benefitsState,
  setBenefitsState,
}) => {
  const addBenefits = () => {
    const benefitsLength = Object.keys(benefitsState).length;
    const extraBenefits = {};

    for (let i = benefitsLength + 1; i <= benefitsLength + 3; i++) {
      extraBenefits[`benefit${i}`] = {
        no: i,
        ...commonInitialBenefitState,
      };
    }

    setBenefitsState(prev => ({
      ...prev,
      ...extraBenefits,
    }));
  };

  const toggleEnabled = () => {
    setState(prev => ({ ...prev, enabled: !prev.enabled }));
  };

  const handleTitleChange = ({ target }) => {
    const title = target?.value;

    setState(prev => ({ ...prev, title }));
  };

  const handleContentChange = event => {
    const content = event.target?.value;

    setState(prev => ({ ...prev, content }));
  };

  const handleTextChange = benefit => event => {
    const innerKey = event.target.name;
    const outerKey = `benefit${benefit}`;
    const value = event.target.value;

    setBenefitsState(prev => ({
      ...prev,
      [outerKey]: {
        ...prev[outerKey],
        [innerKey]: value,
      },
    }));
  };

  const getImage = (stateName, data) => {
    const currentState = {
      ...benefitsState[stateName],
      image: data['@id'] || null,
    };
    setBenefitsState(prev => ({ ...prev, [stateName]: currentState }));
  };

  const removeImage = stateName => {
    setBenefitsState(prev => ({
      ...prev,
      [stateName]: {
        ...prev[stateName],
        image: null,
        imageUrl: null,
      },
    }));
  };

  const benefits = Object.values(benefitsState);

  return (
    <CardWrapper title={t('cms.homePage.description.title')}>
      <CardBody>
        <GridContainer>
          <GridItem md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleEnabled}
                  checked={state.enabled}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={<div>{t('cms.homePage.description.show')} </div>}
            />
          </GridItem>
          <GridItem md={6}>
            <FormTextInput
              label={
                <div style={{ display: 'flex' }}>
                  <span>{t('cms.homePage.sectionTitle')}</span>
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline-block',
                    }}
                  >
                    <Tooltip
                      title={
                        <div>
                          <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                          <h4>
                            {t('brandCfg.etc')}{' '}
                            {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                          </h4>
                        </div>
                      }
                      placement="right"
                    >
                      <Info fontSize={'small'} />
                    </Tooltip>
                  </div>
                </div>
              }
              classes={classes}
              name="title"
              value={state.title}
              handleChange={handleTitleChange}
              inputSize={12}
              maxLength={1000}
              rows={2}
              rowsMax={10}
              multiline={true}
            />
          </GridItem>
          <GridItem md={12}>
            <div>{t('cms.homePage.sectionContent')}</div>
            <SimpleWysiwyg
              handleChange={handleContentChange}
              value={state.content}
            />
          </GridItem>
          <GridItem md={12}>
            <div style={{ marginTop: '20px' }}>
              {t('cms.homePage.description.benefitsTitle')}
            </div>
          </GridItem>
          {benefits.map(benefit => (
            <GridItem md={4}>
              <Benefit
                label={`${t('cms.homePage.description.benefit')} ${benefit.no}`}
                stateName="benefitImage"
                getImage={getImage}
                removeImage={removeImage}
                handleChange={handleTextChange(benefit.no)}
                benefit={benefitsState[`benefit${benefit.no}`]}
              />
            </GridItem>
          ))}
          <GridItem md={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={addBenefits}
            >
              <Add
                className={[classes.simple, classes.primary]}
                fontSize="large"
              />
              <span>{t('cms.homePage.description.addBenefits')}</span>
            </div>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(Description);
